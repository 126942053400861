import SpeechRecognition from 'react-speech-recognition';
// @ts-ignore
import createSpeechServicesPolyfill from 'web-speech-cognitive-services';

import { ERROR_MESSAGE } from 'Constants';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getSpeechToken = (builder: AuroraEndpointBuilder) =>
    builder.query<void, void>({
        query: () => '/v1/Speech/GetSpeechToken',
        transformResponse: async (response: { data: { data: string } }) => {
            const AUTHORIZATION_TOKEN = response.data;
            const REGION = process.env.REACT_APP_SPEECH_SERVICE_REGION;
            const { SpeechRecognition: AzureSpeechRecognition } =
                createSpeechServicesPolyfill({
                    credentials: {
                        region: REGION,
                        authorizationToken: AUTHORIZATION_TOKEN,
                    },
                });

            SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.SpeechTokenGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
    });

export enum Role {
    Admin,
    Retail,
    Support,
}

export enum Feature {
    AdminInterface,
    AttachFiles,
    BetaFlag,
    SpeechInput,
}

export const FEATURE_CONFIG: Record<Feature, Role[]> = {
    [Feature.AdminInterface]: [Role.Admin],
    [Feature.AttachFiles]: [Role.Admin, Role.Support],
    [Feature.BetaFlag]: [Role.Admin, Role.Support],
    [Feature.SpeechInput]: [Role.Admin, Role.Support],
};

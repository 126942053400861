import { ERROR_MESSAGE } from 'Constants';
import { AuroraEndpointBuilder, auroraApi } from 'Services/API/Aurora';
import { GetThreadDetailsResponse } from 'Services/API/Aurora/dto';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getThreadDetails = (builder: AuroraEndpointBuilder) =>
    builder.query<GetThreadDetailsResponse, number>({
        query: (threadId) => `/v1/Threads/GetThreadDetails?Id=${threadId}`,
        transformResponse: (response: { data: GetThreadDetailsResponse }) => {
            return response.data;
        },
        transformErrorResponse: (response: { status: string | number }) => {
            return response.status;
        },
        providesTags: () => [{ type: 'ThreadDetails' }],
        async onQueryStarted(threadId, { dispatch, queryFulfilled }) {
            try {
                const result = await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData('getThreads', 1, (draft) => {
                        const thread = draft.items.find(
                            (item) => item.id === threadId,
                        );
                        // If the current title is New Chat and we receive an actual title
                        // set it and surface the thread
                        if (
                            thread &&
                            thread.title === 'New Chat' &&
                            result.data.title !== 'New Chat' // if it's not the default title from the cache
                        ) {
                            thread.timeStamp = new Date().toISOString();
                            thread.title = result.data.title;
                            thread.isHidden = false;
                        }
                    }),
                );
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadDetailsGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
    });

import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { GetThreadsResponse } from 'Services/API/Aurora/dto';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const deleteAllThreads = (builder: AuroraEndpointBuilder) =>
    builder.mutation<number[], { includeFavorites: boolean }>({
        query: (body) => ({
            url: `/v1/Threads/DeleteAllThreads?deleteFavorites=${body.includeFavorites}`,
            method: 'DELETE',
            body,
        }),
        async onQueryStarted(
            args: { includeFavorites: boolean },
            { dispatch, queryFulfilled },
        ) {
            try {
                await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreads',
                        0,
                        (draft: GetThreadsResponse) => {
                            if (args.includeFavorites) {
                                // Delete all threads
                                draft.items = [];
                            } else {
                                // Delete only threads where isFavorited is false
                                draft.items = draft.items.filter(
                                    (item) => item.isFavorite,
                                );
                            }
                        },
                    ),
                );
                dispatch(
                    setToastMessage({
                        title: 'Threads deleted successfully.',
                        body: '',
                        position: 'top-start',
                        intent: 'success',
                    }),
                );
            } catch (e) {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadDelete,
                        position: 'top-start',
                    }),
                );
            }
        },
        invalidatesTags: ['Threads', 'ThreadDetails'],
    });

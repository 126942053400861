import { ERROR_MESSAGE } from 'Constants';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const downloadThreadAttachment = (builder: AuroraEndpointBuilder) =>
    builder.query<
        string,
        {
            threadId: number;
            attachmentId: number;
            isResponseSupplement: boolean;
        }
    >({
        queryFn: async (
            { threadId, attachmentId, isResponseSupplement },
            { dispatch },
            _extraOptions,
            baseQuery,
        ) => {
            const result = await baseQuery({
                url: `/v1/Threads/DownloadThreadAttachment?threadId=${threadId}&attachmentId=${attachmentId}&isResponseAttachment=${isResponseSupplement}`,
                responseHandler: (response) => response.blob(),
            });
            if (result.error) {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.FileDownload,
                        body: '',
                        position: 'bottom',
                    }),
                );
                return { data: '' };
            }

            const url = window.URL || window.webkitURL;
            const objectUrl = url.createObjectURL(result?.data as Blob);
            return { data: objectUrl };
        },
    });

import React, { useEffect, useMemo } from 'react';

import { AuroraChatCards } from 'Components/AuroraChatCards';
import { UserChatCards } from 'Components/UserChatCards';
import 'Styles/ChatCards.Module.css';
import { SkeletonChatCards } from 'Containers/SkeletonChatCards';
import { useAppSelector } from 'Hooks/useAppSelector';
import { ChatResponseApiResource } from 'Models/ChatThread';
import {
    useGetThreadDetailsQuery,
    useGetThreadMessagesQuery,
} from 'Services/API/Aurora';
import {
    setCanFetchMoreMessages,
    setIsFetchingMessages,
    setMessagesLoading,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

type Props = {
    fetchPageNumber: number;
};

// Scrollbar for chat cards
const ChatMessagesContainerInner: React.FC<Props> = ({
    fetchPageNumber,
}: Props) => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    const threadId = useAppSelector((state) => state.thread.selectedThreadId);
    const { data: threadDetails } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const chatHistoryLimit = useAppSelector(
        (store) => store.user.chatHistoryLimit,
    );

    const chatHistoryLimitForThread =
        threadDetails?.chatHistoryLimit ?? chatHistoryLimit;

    const isPostingMessage = useAppSelector(
        (state) => state.thread.isPostingMessage,
    );

    const isRetrying = useAppSelector((state) => state.thread.isRetrying);

    const { data, isFetching: isFetchingMessages } = useGetThreadMessagesQuery(
        {
            threadId: threadId ?? 0,
            pageNumber: fetchPageNumber || 1,
        },
        {
            skip: !threadId,
        },
    );

    const messages = data?.items ?? [];

    useEffect(() => {
        if (isFetchingMessages) {
            updateStore(setMessagesLoading(true));
            return;
        }
        updateStore(setMessagesLoading(false));
    }, [isFetchingMessages]);

    useEffect(() => {
        updateStore(
            setCanFetchMoreMessages(fetchPageNumber < (data?.pageCount ?? 0)),
        );
    }, [data, fetchPageNumber]);

    useEffect(() => {
        updateStore(setIsFetchingMessages(isFetchingMessages));
    }, [isFetchingMessages]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isFetchingMessages && fetchPageNumber === 1) {
        return <SkeletonChatCards />;
    }

    return (
        <div style={{ width: '100%', marginRight: '3px' }}>
            {messages.map((message: ChatResponseApiResource, index) => {
                const isHistory =
                    index >= messages.length - chatHistoryLimitForThread * 2;
                // If its a User Message send the following values
                if (message.role === 'User') {
                    return (
                        <UserChatCards
                            key={index}
                            id={message.id}
                            message={message}
                            content={message.content as any}
                            windowWidth={windowWidth}
                            inHistory={isHistory}
                            messageDate={message.createdAt}
                            references={message.references}
                            didError={
                                index === messages.length - 1 &&
                                message.parentId === null &&
                                !isPostingMessage &&
                                !isRetrying
                            }
                        />
                    );
                }
                // If its an Aurora Message send the following values
                else if (message.role === 'Aurora') {
                    return (
                        <AuroraChatCards
                            key={index}
                            message={message}
                            windowWidth={windowWidth}
                            inHistory={isHistory}
                        />
                    );
                }
                // If another role is listed return null (error)
                return null;
            })}
        </div>
    );
};

export const ChatMessagesContainer = React.memo(ChatMessagesContainerInner);

import { ERROR_MESSAGE } from 'Constants';
import { User } from 'Models/User';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const patchUser = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, User>({
        query: (body) => ({
            url: '/v1/Users/UpdateUser',
            method: 'PATCH',
            body,
        }),
        async onQueryStarted(
            { id, chatHistoryLimit, theme, beta },
            { dispatch, queryFulfilled },
        ) {
            const result = dispatch(
                auroraApi.util.updateQueryData(
                    'getUser',
                    undefined,
                    (draft) => {
                        draft.beta = beta;
                        draft.chatHistoryLimit = chatHistoryLimit;
                        draft.theme = theme;
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch {
                result.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.UserUpdate,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
        invalidatesTags: ['User'],
    });

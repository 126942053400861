import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const patchThreadChatHistoryLimit = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { threadId: number; chatHistoryLimit: number }>({
        query: (body) => ({
            url: '/v1/Threads/SetThreadChatHistoryLimit',
            method: 'PATCH',
            body,
        }),
        async onQueryStarted(
            { threadId, chatHistoryLimit },
            { dispatch, queryFulfilled },
        ) {
            const updateChatHistoryLimit = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    threadId,
                    (draft) => {
                        draft.chatHistoryLimit = chatHistoryLimit;
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch {
                updateChatHistoryLimit.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ContextUpdate,
                        body: '',
                        position: 'top-end',
                    }),
                );
            }
        },
    });

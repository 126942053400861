import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    DialogActions,
    Button,
} from '@fluentui/react-components';
import { useEffect, useRef } from 'react';

type Props = {
    dialogOpen: boolean;
    handleConfirmClick: () => void;
    text: string;
    handleCancelClick?: () => void;
};

export const ConfirmDialog = ({
    dialogOpen,
    handleConfirmClick,
    handleCancelClick,
    text,
}: Props): JSX.Element => {
    const confirmRef = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
        if (dialogOpen) {
            confirmRef.current?.focus();
        }
    }, [dialogOpen]);
    return (
        <>
            <Dialog open={dialogOpen}>
                <DialogSurface>
                    <DialogBody
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            {' '}
                            <DialogContent
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    fontSize: '15px',
                                }}
                            >
                                {text}
                            </DialogContent>
                        </div>

                        <div>
                            <DialogActions>
                                {handleCancelClick && (
                                    <Button
                                        onClick={handleCancelClick}
                                        appearance="secondary"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    onClick={handleConfirmClick}
                                    appearance="primary"
                                    ref={confirmRef}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </div>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

import { unionBy } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { ISkill } from 'Models/Skill';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { PatchThreadSkillsRequest } from 'Services/API/Aurora/dto';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const patchThreadSkills = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, PatchThreadSkillsRequest>({
        query: ({ threadId, setSkills }) => ({
            url: '/v1/Threads/SetThreadSkills',
            method: 'PATCH',
            body: { threadId, setSkills },
        }),
        async onQueryStarted(
            args: {
                threadId: number;
                setSkills: Array<{
                    skillid: number;
                    isSelected: boolean;
                }>;
                setLocalSkills: Array<ISkill>;
            },
            { dispatch, queryFulfilled },
        ) {
            const updateSkills = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    args.threadId,
                    (draft) => {
                        // These are the changes in the local format for skills (vs the diff we send in the api)
                        args.setLocalSkills.forEach((newSkill) => {
                            const draftSkill = draft.skills?.find(
                                (s) => s.id === newSkill.id,
                            );
                            if (draftSkill) {
                                draftSkill.isSelected = newSkill.isSelected;
                            }
                            newSkill.childSkills.forEach((newChildSkill) => {
                                const draftChildSkill =
                                    draftSkill?.childSkills?.find(
                                        (s) => s.id === newChildSkill.id,
                                    );
                                if (draftChildSkill) {
                                    draftChildSkill.isSelected =
                                        newChildSkill.isSelected;
                                }
                            });
                        });
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch (e) {
                updateSkills.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.SkillsUpdate,
                        body: '',
                        position: 'top-end',
                    }),
                );
            }
        },
    });

import {
    tokens,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    Button,
} from '@fluentui/react-components';
import { DismissFilled, ArrowDownloadRegular } from '@fluentui/react-icons';

import {
    downloadImageFromUrl,
    downloadSharepointImageFromUrl,
} from 'Utils/index';

type ImageDialogProps = {
    isOpen: boolean;
    imageUrl: string | null;
    imageName: string | null;
    onClose: () => void;
};

export const ImageDialog = ({
    isOpen,
    imageUrl,
    imageName,
    onClose,
}: ImageDialogProps): JSX.Element => {
    return (
        <>
            {isOpen && (
                <>
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            backdropFilter: 'blur(5px)',
                            zIndex: 999,
                        }}
                        onClick={onClose}
                    />
                    <div
                        style={{
                            position: 'fixed',
                            top: '20px',
                            right: '20px',
                            display: 'flex',
                            gap: '10px',
                            zIndex: 1000,
                        }}
                    >
                        <Button
                            icon={<ArrowDownloadRegular />}
                            onClick={() =>
                                imageUrl && !imageUrl.startsWith('http')
                                    ? downloadImageFromUrl(
                                          imageUrl,
                                          imageName || 'image.png',
                                      )
                                    : imageUrl &&
                                      downloadSharepointImageFromUrl(imageUrl)
                            }
                            style={{
                                padding: '5px',
                                backgroundColor: tokens.colorNeutralForeground1,
                                color: tokens.colorNeutralForegroundInverted,
                            }}
                        />
                        <Button
                            icon={<DismissFilled />}
                            onClick={onClose}
                            style={{
                                padding: '5px',
                                backgroundColor: tokens.colorNeutralForeground1,
                                color: tokens.colorNeutralForegroundInverted,
                            }}
                        />
                    </div>
                </>
            )}

            <Dialog
                modalType="non-modal"
                open={isOpen}
                onOpenChange={(event, { open }) => (open ? null : onClose())}
            >
                <DialogSurface
                    style={{
                        maxWidth: 'fit-content',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DialogBody>
                        <DialogContent
                            className="custom-scrollbar"
                            style={{
                                position: 'relative',
                                maxWidth: '100vw',
                                maxHeight: '80vh',
                                width: 'auto',
                            }}
                        >
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt="Preview"
                                    style={{
                                        maxWidth: '100vw',
                                        maxHeight: '80vh',
                                        width: 'auto',
                                        height: 'auto',
                                    }}
                                />
                            )}
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

import {
    Button,
    Card,
    Input,
    RadioGroup,
    Skeleton,
    SkeletonItem,
    Subtitle1,
    Table,
    TableBody,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import {
    useCreateBannerMutation,
    useGetActiveBannerQuery,
    useGetBannersQuery,
} from 'Services/API/Aurora';

import { BannerTableRow } from './BannerTableRow';

export const AdminBanner: React.FC = () => {
    const [createBanner, { isSuccess: isCreateBannerSuccess }] =
        useCreateBannerMutation();

    const { data: banners, isLoading: isLoadingBanners } =
        useGetBannersQuery(1);
    const { data: activeBanner, isLoading: isLoadingActiveBanner } =
        useGetActiveBannerQuery();

    const [newBannerMessage, setNewBannerMessage] = useState('');

    useEffect(() => {
        if (isCreateBannerSuccess) {
            setNewBannerMessage('');
        }
    }, [isCreateBannerSuccess]);

    if (isLoadingBanners || isLoadingActiveBanner) {
        return (
            <div
                style={{
                    width: '100%', // Take full width to allow centering
                    height: '100%', // Take full height to allow centering
                    display: 'flex',
                    marginTop: '100px',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style={{
                        width: '500px',
                        height: '196px',
                        opacity: 0.8,
                        backgroundColor: 'var(--colorNeutralBackground2)',
                    }}
                >
                    <Skeleton width="500px">
                        <SkeletonItem
                            style={{
                                backgroundColor:
                                    'var(--colorNeutralBackground1)',
                            }}
                        />
                        {[...Array(6)].map(() => (
                            <SkeletonItem
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        ))}
                    </Skeleton>
                </Card>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Center horizontally
                width: '100%', // Take full width to allow centering
                height: '100%', // Take full height to allow centering
                padding: '20px',
                overflow: 'scroll',
            }}
        >
            <Subtitle1 as="h4" block>
                Banners
            </Subtitle1>
            <Table style={{ maxWidth: '500px' }}>
                <TableBody>
                    <RadioGroup value={activeBanner?.id?.toString() ?? 'none'}>
                        {banners?.items.map((banner, index) => (
                            <BannerTableRow
                                banner={banner}
                                activeBanner={activeBanner}
                            />
                        ))}
                        <BannerTableRow
                            banner={{ id: null, message: 'None' }}
                            activeBanner={activeBanner}
                        />
                    </RadioGroup>
                </TableBody>
            </Table>
            <div
                style={{
                    maxWidth: '500px',
                    width: '500px',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                }}
            >
                <div style={{ height: '24px' }} />
                <Input
                    style={{ marginBottom: '8px' }}
                    value={newBannerMessage}
                    onChange={(e) => setNewBannerMessage(e.target.value)}
                    placeholder="Enter new banner content"
                />
                <Button
                    style={{ width: '150px' }}
                    onClick={async () => {
                        const result = await createBanner({
                            message: newBannerMessage,
                        });
                        if ('data' in result) {
                            setNewBannerMessage('');
                        }
                    }}
                    disabled={newBannerMessage.trim() === ''}
                >
                    Create Banner
                </Button>
            </div>
        </div>
    );
};

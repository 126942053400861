import { unionBy } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { Banner } from 'Models/Banner';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getActiveBanner = (builder: AuroraEndpointBuilder) =>
    builder.query<Banner, void>({
        query: () => `/v1/TermsConditions/GetBanner`,
        transformResponse: (response: { data: Banner }) => {
            return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.BannersGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
        providesTags: () => [{ type: 'Banners' }],
    });

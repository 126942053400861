import {
    InteractionRequiredAuthError,
    IPublicClientApplication,
    AccountInfo,
} from '@azure/msal-browser';

export async function acquireToken(
    instance: IPublicClientApplication,
    account: AccountInfo | undefined,
    scopes: string[],
    redirectOnTokenAcquisitionError: boolean = true,
): Promise<string | undefined> {
    const requestParameters = {
        scopes: scopes,
        account: account,
    };
    try {
        const tokenResponse = await instance.acquireTokenSilent({
            ...requestParameters,
            redirectUri: `${window.location.origin}`,
        });
        return tokenResponse.accessToken;
    } catch (error) {
        console.error('error in acquiring token silently', error);
        if (
            error instanceof InteractionRequiredAuthError &&
            redirectOnTokenAcquisitionError
        ) {
            try {
                await instance.acquireTokenRedirect(requestParameters);
            } catch (error) {
                console.error('error in invoking acquireTokenRedirect', error);
                throw error;
            }
        } else {
            throw error;
        }
    }
}

export async function msalSignOut(
    instance: IPublicClientApplication,
): Promise<void> {
    await instance.logoutRedirect();
}

import { ERROR_MESSAGE } from 'Constants';
import { Skill } from 'Models/Skill';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const patchUserSkills = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, Skill[]>({
        query: (body) => ({
            url: '/v1/UserSkills/UpdateUserSkills',
            method: 'PATCH',
            body,
        }),
        async onQueryStarted({}, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
                dispatch(
                    setToastMessage({
                        title: 'Settings saved successfully.',
                        body: '',
                        position: 'bottom',
                        intent: 'success',
                    }),
                );
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.UserUpdate,
                        body: '',
                        position: 'bottom',
                    }),
                );
            }
        },
        invalidatesTags: ['UserSkills'],
    });

import { shorthands, makeStyles, tokens } from '@fluentui/react-components';

import { ErmContainer } from 'Containers/ErmContainer';

import 'Styles/ErmBorder.Module.css';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        backgroundColor: tokens?.colorNeutralBackground2,
        height: '100vh',

        ...shorthands.overflow('hidden'),
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding('16px'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
});

export const Erm: React.FC = () => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <ErmContainer />
        </div>
    );
};

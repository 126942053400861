import {
    makeStyles,
    shorthands,
    tokens,
    Subtitle1,
    Image,
    Button,
    Textarea,
    TextareaOnChangeData,
} from '@fluentui/react-components';
import { SendRegular, EditRegular } from '@fluentui/react-icons';
import 'Styles/ChatCards.Module.css';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from 'Hooks/useAppSelector';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

import 'Styles/ErmMicrophone.Module.css';

type Props = {
    isSendMessageButtonDisabled: boolean;
    onSendMessageButtonClick: (message: string) => Promise<void>;
    resetTranscript: () => void;
    transcript: string;
    isRecording: boolean;
    toggleRecording: (isRecording: boolean) => void;
    onChange: (message: string) => void;
    userMessage: PostErmMessageRequest;
    isNewMessage: boolean;
    newChatAction: string;
};

const useStyles = makeStyles({
    title: {
        ...shorthands.margin(0, 0, '10px'),
        textAlign: 'center',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};

export const ErmMessageCard: React.FC<Props> = (props: Props) => {
    const ErmLoading = useAppSelector(
        (store) => store.userInterface.ErmLoading,
    );
    const {
        onSendMessageButtonClick,
        toggleRecording,
        resetTranscript,
        transcript,
        isRecording,
        onChange,
        userMessage,
        isNewMessage,
        newChatAction,
    } = props;

    const [message, setMessage] = useState('');
    const [sendButton, setSendButton] = useState(false);
    const [editText, setEditText] = useState(false);
    const sendButtonRef = useRef<HTMLButtonElement>(null);
    const textAreaRef = useRef<HTMLDivElement>(null);
    const textareaInputRef = useRef<HTMLTextAreaElement>(null);
    const isChatDisabled = useAppSelector(
        (store) => store.userInterface.isChatDisabled,
    );

    // UseEffect for focusing on the text area when the user clicks on the card (edit text = true)
    useEffect(() => {
        if (editText && textareaInputRef.current) {
            const textarea = textareaInputRef.current;
            textarea.focus();
            textarea.setSelectionRange(
                textarea.value.length,
                textarea.value.length,
            );
        }
    }, [editText]);

    // UseEffect for handling clicks outside the text area
    const handleClickOutside = (event: MouseEvent) => {
        if (
            textAreaRef.current &&
            !textAreaRef.current.contains(event.target as Node) &&
            (!sendButtonRef.current ||
                !sendButtonRef.current.contains(event.target as Node))
        ) {
            setEditText(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (newChatAction === 'setRecordingActive') {
            toggleRecording(true);
        } else if (newChatAction === 'setTextareaActive') {
            setEditText(true);
        }
    }, [newChatAction, toggleRecording]);

    // Function for sending message
    const clickSendButton = async () => {
        if (message.trim() !== '') {
            setEditText(false);
            setSendButton(false);
            await onSendMessageButtonClick(message);
            setMessage('');
        }
    };

    // Initiate/end recording when the Aurora logo is clicked and log message if there is one
    const clickAuroraLogo = async () => {
        toggleRecording(!isRecording);
        if (transcript.trim() != '' && isRecording) {
            setMessage(transcript);
            setSendButton(true);
        }
    };

    // Send message when Enter key is pressed - may remove this when moving to mobile
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            clickSendButton();
        }
    };

    // Logic for displaying the prompt card message
    let promptCard = 'How can I help you today?';
    if (isRecording) {
        transcript === '' ? (promptCard = '. . .') : (promptCard = transcript);
    } else if (message !== '') {
        promptCard = message;
    } else if (userMessage.message !== '' && !isNewMessage) {
        promptCard = userMessage.message;
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                paddingTop: '20px',
                paddingBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button
                    appearance="subtle"
                    style={{
                        background: 'transparent',
                        padding: '20px',
                        paddingTop: '50px',
                        overflow: 'visible',

                        WebkitTapHighlightColor: 'transparent',
                    }}
                    disabled={ErmLoading || !isNewMessage}
                    onClick={() => {
                        clickAuroraLogo();
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                        }}
                    >
                        <Image
                            src="/logo192.png"
                            shape="circular"
                            height={100}
                            width={100}
                            style={{
                                position: 'relative',
                                marginBottom: '50px',
                                marginTop: '20px',
                                zIndex: 2,
                            }}
                        />
                        {isRecording ? (
                            <div
                                className="mic-shadow"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '0',
                                    width: '100px',
                                    height: '100px',
                                    zIndex: 1,
                                }}
                            ></div>
                        ) : null}
                        {ErmLoading ? (
                            <div
                                className="mic-loading"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '0',
                                    width: '100px',
                                    height: '100px',
                                    zIndex: 1,
                                }}
                            ></div>
                        ) : null}
                    </div>
                </Button>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {' '}
                    <div
                        style={{
                            backgroundColor: tokens.colorNeutralBackground6,
                            paddingTop: '7px',
                            paddingBottom: '0px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            borderRadius: '10px',
                            marginRight: '20px',
                            marginLeft: '20px',
                            width: editText ? '50vw' : 'auto',
                        }}
                        ref={textAreaRef}
                        onClick={() =>
                            !ErmLoading && !isRecording && isNewMessage
                                ? setEditText(true)
                                : null
                        }
                    >
                        {!editText ? (
                            <>
                                <Title>
                                    {promptCard}
                                    {promptCard !== '. . .' &&
                                    isNewMessage &&
                                    !ErmLoading ? (
                                        <EditRegular
                                            style={{
                                                marginLeft: '10px',
                                                height: '18px',
                                            }}
                                        />
                                    ) : null}
                                </Title>
                            </>
                        ) : (
                            <Textarea
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    marginBottom: '10px',
                                    width: '100%',
                                }}
                                ref={textareaInputRef}
                                defaultValue={message}
                                onKeyDown={handleKeyDown}
                                disabled={isRecording || ErmLoading}
                                onChange={(
                                    _event: React.ChangeEvent<HTMLTextAreaElement>,
                                    data: TextareaOnChangeData,
                                ) => {
                                    onChange(data.value);
                                    setMessage(data.value);
                                    resetTranscript();
                                    setSendButton(data.value !== '');
                                }}
                            ></Textarea>
                        )}
                    </div>
                    {sendButton && !isRecording ? (
                        <Button
                            ref={sendButtonRef}
                            appearance="subtle"
                            aria-label="Bold"
                            icon={<SendRegular />}
                            name="send"
                            value="send"
                            disabled={isChatDisabled}
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            onClick={() => {
                                clickSendButton();
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

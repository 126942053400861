import {
    Card,
    CardHeader,
    Spinner,
    Body1,
    Button,
    Link,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { useCallback } from 'react';

import {
    useLazyDownloadThreadAttachmentQuery,
    useRemoveThreadAttachmentMutation,
} from 'Services/API/Aurora';
import { getFileIcon, isImageFile } from 'Utils';

type Props = {
    index: number;
    name: string;
    threadId?: number;
    url?: string;
    id?: number;
    isLoading?: boolean;
    disableDismissButton?: boolean;
    role?: string;
};

export const FileCard = ({
    threadId,
    name,
    url,
    id,
    isLoading,
    index,
    disableDismissButton,
    role,
}: Props): JSX.Element => {
    const [removeThreadAttachment] = useRemoveThreadAttachmentMutation();
    const [fetchAttachment] = useLazyDownloadThreadAttachmentQuery();

    const handleLinkClick = useCallback(async () => {
        if (url && url.startsWith('http')) {
            window.open(url, '_blank');
            return;
        }
        const result = await fetchAttachment({
            threadId: threadId ?? 0,
            attachmentId: id ?? 0,
            isResponseSupplement: role === 'Aurora',
        });
        if (result.data) {
            const hiddenLink = document.createElement('a');
            hiddenLink.href = result.data;
            hiddenLink.target = '_blank';
            hiddenLink.download = name;
            hiddenLink.click();
        }
    }, [fetchAttachment, id, name, role, threadId, url]);

    const handleDismiss = useCallback(() => {
        removeThreadAttachment({
            threadId: threadId ?? 0,
            attachmentId: id ?? 0,
        });
    }, [id, removeThreadAttachment, threadId]);

    return (
        <Card
            key={index}
            style={{
                marginBottom: '5px',
                backgroundColor: 'var(--colorNeutralBackground5Hover)',
                border: '1px solid var(--colorNeutralBackground3Hover)',
                height: '42px',
                display: 'flex',
                justifyContent: 'center',
            }}
            data-testid="attached-document-card"
        >
            <CardHeader
                image={
                    isLoading ? (
                        <Spinner size="tiny" />
                    ) : (
                        <img
                            src={getFileIcon(name)}
                            alt="File icon"
                            style={{ width: '20px' }}
                        />
                    )
                }
                header={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Body1>
                            <Link
                                onClick={handleLinkClick}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: 'var(--colorStrokeFocus2)',
                                }}
                            >
                                {name}
                            </Link>
                        </Body1>
                        <div>
                            {!disableDismissButton && (
                                <Button
                                    aria-label="dismiss"
                                    appearance="transparent"
                                    icon={
                                        <DismissRegular
                                            onClick={handleDismiss}
                                        />
                                    }
                                />
                            )}
                        </div>
                    </div>
                }
            />
        </Card>
    );
};

import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const deleteBanner = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { id: number }>({
        query: (body) => ({
            url: '/v1/TermsConditions/DeleteBanner',
            method: 'DELETE',
            body,
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
            const bannerUpdate = dispatch(
                auroraApi.util.updateQueryData('getBanners', 0, (draft) => {
                    const deletedBannerIndex = draft.items.findIndex(
                        (banner) => banner.id === id,
                    );
                    if (deletedBannerIndex >= 0) {
                        draft.items.splice(deletedBannerIndex, 1);
                    }
                }),
            );
            try {
                await queryFulfilled;
            } catch {
                bannerUpdate.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.BannersDelete,
                        position: 'top',
                    }),
                );
            }
        },
        invalidatesTags: ['Banners'],
    });

import { Feature, FEATURE_CONFIG } from 'Config/features';
import { useRole } from 'Hooks/useRole';

export const useFeature = (feature: Feature): boolean => {
    const role = useRole();
    if (FEATURE_CONFIG[feature].includes(role)) {
        return true;
    }
    return false;
};

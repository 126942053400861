import { cloneDeep } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { AttachedFile, ChatResponseApiResource } from 'Models/ChatThread';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { PostMessageRequest } from 'Services/API/Aurora/dto';
import {
    setIsNewThread,
    setToastMessage,
} from 'Services/StateManagement/Actions';

export const postMessage = (builder: AuroraEndpointBuilder) =>
    builder.mutation<{ data: ChatResponseApiResource }, PostMessageRequest>({
        query: (args) => ({
            url: args.isRetry
                ? '/v1/Chat/RetrySendMessage'
                : '/v1/Chat/SendMessage',
            method: 'POST',
            body: {
                threadId: args.threadId,
                message: args.message,
                retryReferenceId: args.retryReferenceId,
            },
        }),
        async onQueryStarted(
            { threadId, message, isRetry, retryReferenceId },
            { dispatch, queryFulfilled },
        ) {
            const TEMP_ID = 0 - new Date().getTime();
            let attachmentsFromThreadDetails: AttachedFile[] | null | undefined;
            // optimistically updates thread date to move to the top of list
            const threadUpdateResult = dispatch(
                auroraApi.util.updateQueryData('getThreads', 0, (draft) => {
                    const thread = draft.items.find(
                        (item) => item.id === threadId,
                    );
                    if (thread) {
                        thread.timeStamp = new Date().toISOString();
                        thread.isHidden = false;
                    }
                }),
            );

            // optimistically updates thread details
            dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    threadId,
                    (draft) => {
                        draft.messageCount = (draft.messageCount ?? 0) + 1;
                        attachmentsFromThreadDetails = cloneDeep(
                            draft.attachments,
                        );
                    },
                ),
            );

            // Optimistically updates the chat message cache with the new user message
            dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadMessages',
                    { threadId, pageNumber: 1 },
                    (draft) => {
                        // skip re-adding message if it's a retry
                        if (isRetry) {
                            return;
                        }
                        // Reset retry buttons
                        draft.items.forEach((item) => {
                            item.didError = false;
                        });
                        const newMessage: ChatResponseApiResource = {
                            id: TEMP_ID,
                            threadId,
                            thumbUp: false,
                            thumbDown: false,
                            isHistory: false,
                            role: 'User',
                            createdAt: new Date().toISOString(),
                            content: [
                                {
                                    type: 'text',
                                    value: message,
                                    language: '',
                                },
                            ],
                            parentId: -1,
                            references: attachmentsFromThreadDetails?.map(
                                (attachment, i) => ({
                                    attachmentId: attachment.id,
                                    url: attachment.url ?? null,
                                    name: attachment.name,
                                    location: '',
                                    number: i,
                                    role: attachment.role,
                                    type: attachment.type,
                                }),
                            ),
                            retryReferenceId,
                            retryCount: 0,
                        };
                        draft.items.push(newMessage);
                        draft.totalItemCount = (draft.totalItemCount ?? 0) + 1;
                        draft.pageCount = draft.pageCount ? draft.pageCount : 1;
                    },
                ),
            );

            try {
                const result = await queryFulfilled;
                // Handle chat response
                // Note: if postMessage rest call finishes before getThreadMessages is initialized, this fails and causes the bug
                // where the first message pops up as errored.
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadMessages',
                        { threadId, pageNumber: 1 },
                        (draft) => {
                            // If message is retried, reset flag
                            if (isRetry) {
                                draft.items[draft.items.length - 1].didError =
                                    false;
                            }
                            // Add response message to the chat
                            draft.items.push(result.data.data);
                            draft.totalItemCount = draft.totalItemCount + 1;
                        },
                    ),
                );
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadDetails',
                        threadId,
                        (draft) => {
                            draft.messageCount = (draft.messageCount ?? 0) + 1;
                        },
                    ),
                );
                dispatch(setIsNewThread(false));
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.MessagePost,
                        body: '',
                        position: 'bottom',
                    }),
                );

                // Flags the user message if it fails
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadMessages',
                        { threadId, pageNumber: 0 },
                        (draft) => {
                            const userMessage = draft.items.find(
                                (item) => item.id === TEMP_ID,
                            );
                            if (userMessage) {
                                userMessage.didError = true;
                            }
                        },
                    ),
                );
                dispatch(setIsNewThread(false));
                // undoes the optimistic update if the call fails
                threadUpdateResult.undo();
                /**
                 * Alternatively, on failure you can invalidate the corresponding cache tags
                 * to trigger a re-fetch:
                 * dispatch(api.util.invalidateTags(['Messages']))
                 */
            }
        },
        invalidatesTags: (result) => [{ type: 'ThreadDetails' }], // to update thread title
    });

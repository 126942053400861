import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedFile } from 'Models/ChatThread';

type ThreadState = {
    selectedThreadId: number | null;
    chatHistoryLimit: number | null; // temporary to deal with old flow
    files: Record<number, SelectedFile[]>;
    pagination: Record<number, number>;
    isRetrying: boolean;
    isCreatingNewThread: boolean;
    isNewThread: boolean;
    isPostingMessage: boolean;
    isFetchingMessages: boolean;
    canFetchMoreMessages: boolean;
};

const initialState: ThreadState = {
    selectedThreadId: null,
    chatHistoryLimit: null,
    files: {},
    pagination: {},
    isRetrying: false,
    isCreatingNewThread: false,
    isNewThread: false,
    isPostingMessage: false,
    isFetchingMessages: false,
    canFetchMoreMessages: false,
};

export const ThreadSlice = createSlice({
    name: 'thread',
    initialState,
    reducers: {
        setSelectedThreadId: (state, action: PayloadAction<number | null>) => {
            state.selectedThreadId = action.payload;
        },
        unselectThreadId: (state) => {
            state.selectedThreadId = null;
            state.chatHistoryLimit = null;
        },
        addLocalFileToThread: (
            state,
            action: PayloadAction<{ threadId: number; file: SelectedFile }>,
        ) => {
            const { threadId, file } = action.payload;
            if (state.files[threadId]) {
                state.files[threadId].push(file);
            } else {
                state.files[threadId] = [file];
            }
        },
        removeLocalFileFromThread: (
            state,
            action: PayloadAction<{ threadId: number; file: SelectedFile }>,
        ) => {
            const { threadId, file } = action.payload;
            const index = state.files[threadId].findIndex((f) => {
                if (file.attachmentId) {
                    return f.attachmentId === file.attachmentId;
                }
                if (file.url) {
                    return f.url === file.url;
                }
            });
            state.files[threadId].splice(index, 1);
        },
        initializeThreadPagination: (
            state,
            action: PayloadAction<{ threadId: number }>,
        ) => {
            state.pagination[action.payload.threadId] = 1;
        },
        incrementThreadPage: (
            state,
            action: PayloadAction<{ threadId: number }>,
        ) => {
            const { threadId } = action.payload;
            state.pagination[threadId]++;
        },
        setIsRetryingTrue: (state) => {
            state.isRetrying = true;
        },
        setIsRetryingFalse: (state) => {
            state.isRetrying = false;
        },
        setIsCreatingNewThread: (state, action: PayloadAction<boolean>) => {
            state.isCreatingNewThread = action.payload;
        },
        setIsNewThread: (state, action: PayloadAction<boolean>) => {
            state.isNewThread = action.payload;
        },
        setIsPostingMessage: (state, action: PayloadAction<boolean>) => {
            state.isPostingMessage = action.payload;
        },
        setIsFetchingMessages: (state, action: PayloadAction<boolean>) => {
            state.isFetchingMessages = action.payload;
        },
        setCanFetchMoreMessages: (state, action: PayloadAction<boolean>) => {
            state.canFetchMoreMessages = action.payload;
        },
    },
});

import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const putBanner = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { id: number; message: string; isActive: boolean }>({
        query: (body) => ({
            url: '/v1/TermsConditions/UpdateBanner',
            method: 'PUT',
            body,
        }),
        async onQueryStarted(
            { id, message, isActive },
            { dispatch, queryFulfilled },
        ) {
            const bannerUpdate = dispatch(
                auroraApi.util.updateQueryData('getBanners', 0, (draft) => {
                    const updatedBanner = draft.items.find(
                        (banner) => banner.id === id,
                    );
                    if (updatedBanner) {
                        updatedBanner.message = message;
                        updatedBanner.isActive = isActive;
                    }
                }),
            );
            try {
                await queryFulfilled;
            } catch {
                bannerUpdate.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.BannersUpdate,
                        position: 'top',
                    }),
                );
            }
        },
        invalidatesTags: ['Banners'],
    });

import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React, { useCallback } from 'react';

import { MarkdownViewer } from 'Components/MarkdownViewer';
import { useTermsAndConditions } from 'Hooks/useAlerts';
import { useAppSelector } from 'Hooks/useAppSelector';
import 'Styles/Header.Module.css';
import { Banner } from 'Models/Banner';

const useStyles = makeStyles({
    leftButtons: {
        ...shorthands.margin('20px'),
    },
    innerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '98%',
        marginLeft: '20px',
    },
    dismissButton: {
        marginRight: '15px',
    },
});

export const BannerContainer: React.FC = () => {
    const styles = useStyles();

    const { getBanner, hideBanner } = useTermsAndConditions();
    // Fetch banner when the component mounts
    React.useEffect(() => {
        getBanner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const banner: Banner = useAppSelector((store) => store.banner);

    // Temporarily dismiss the banner
    const updateBanner = useCallback(() => {
        hideBanner(banner.id);
    }, [banner.id, hideBanner]);

    // If no banner was returned from API, return null
    if (banner.isHidden) {
        return null;
    }
    return (
        <div
            style={{
                backgroundColor: 'var(--colorNeutralBackground6)',
                width: '100%',
                padding: '10px 0',
            }}
            data-testid="banner-component"
        >
            <div className={styles.innerHeader}>
                <div className="messageContainer">
                    <MarkdownViewer content={banner.message} />
                </div>

                <Button
                    appearance="subtle"
                    className={styles.dismissButton}
                    icon={<DismissRegular />}
                    onClick={updateBanner}
                    data-testid="dismiss-banner-button"
                />
            </div>
        </div>
    );
};

import { useMsal } from '@azure/msal-react';
import { ContextTagKeys } from '@microsoft/applicationinsights-common';
import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
    IExceptionTelemetry,
    ITraceTelemetry,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

import { useAppSelector } from 'Hooks/useAppSelector';
import { EventTypes } from 'Models/EventTypes';
import { OperationContext } from 'Models/OperationContext';

type EventData = {
    SessionId: string;
    EventType: EventTypes;
    UserId?: string;
    UserName?: string;
    AdditionalData?: Record<string, any>;
};

export const useLogging = () => {
    const { accounts } = useMsal();
    const globalState = useAppSelector((store) => store.globalState);
    const appInsights = useAppInsightsContext();
    const trackEvent = useTrackEvent(appInsights, 'AuroraWeb', {});

    const logEvent = (
        eventType: EventTypes,
        additionalData?: Record<string, any>,
    ) => {
        const eventData: EventData = {
            SessionId: globalState.sessionId,
            EventType: eventType,
            UserId: accounts[0]?.localAccountId,
            UserName: accounts[0]?.username,
        };
        if (additionalData) {
            eventData.AdditionalData = additionalData;
        }
        trackEvent(eventData);
    };

    const logOperation = (context: OperationContext) => {
        context.userId = accounts[0]?.localAccountId;
        context.userName = accounts[0]?.username;
        const traceTelemetry: ITraceTelemetry = {
            message: context.name,
            severityLevel: SeverityLevel.Verbose,
            properties: {
                name: context.name,
                tags: getTagsFromContext(context),
            },
        };
        appInsights.trackTrace(
            traceTelemetry,
            getCustomPropertiesFromContext(context),
        );
    };

    const logError = (error: Error, context: OperationContext) => {
        const exceptionTelemetry: IExceptionTelemetry = {
            error: error,
            severityLevel: SeverityLevel.Error,
            properties: {
                name: context.name,
                tags: getTagsFromContext(context),
            },
        };
        appInsights.trackException(
            exceptionTelemetry,
            getCustomPropertiesFromContext(context),
        );
    };

    const getTagsFromContext = (context: OperationContext) => {
        const contextTagKeys = new ContextTagKeys();
        return {
            [contextTagKeys.sessionId]: globalState.sessionId,
            [contextTagKeys.operationId]: context.id,
            [contextTagKeys.userId]: context.userId,
        };
    };

    const getCustomPropertiesFromContext = (context: OperationContext) => {
        context.endTime = new Date();
        context.elapsed =
            (context.endTime.getTime() - context.startTime.getTime()) / 1000;
        const customProperties = context.additionalProperties ?? {};
        customProperties['StartTime'] = context.startTime;
        customProperties['EndTime'] = context.endTime;
        customProperties['ElapsedTime'] = context.elapsed;
        customProperties['OperationId'] = context.id;
        customProperties['OperationName'] = context.name;
        customProperties['UserId'] = context.userId;
        customProperties['UserName'] = context.userName;
        customProperties['SessionId'] = globalState.sessionId;
        return customProperties;
    };

    return { logEvent, logOperation, logError };
};

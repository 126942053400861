import { Field, makeStyles, shorthands } from '@fluentui/react-components';
import { QueryStatus } from '@reduxjs/toolkit/query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { EnableMicrophoneInstructions } from 'Components/EnableMicrophoneInstructions';
import { MessageBar } from 'Components/MessageBar';
import { MessageToolBar } from 'Components/MessageToolBar';
import { ScreenPopover } from 'Components/ScreenPopover';
import { Feature } from 'Config/features';
import { ERROR_MESSAGE } from 'Constants';
import { BannerContainer } from 'Containers/BannerContainer';
import { ChatMessagesContainer } from 'Containers/ChatMessagesContainer';
import { HeaderContainer } from 'Containers/HeaderContainer';
import { LoadNewChatCardsContainer } from 'Containers/LoadNewChatCardsContainer';
import { NewChatCardsContainer } from 'Containers/NewChatCardsContainer';
import { SettingsMenuContainer } from 'Containers/SettingsMenuContainer';
import { SkeletonChatCards } from 'Containers/SkeletonChatCards';
import { SelectedSkillsModel } from 'Containers/SkillCardContainer';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useFeature } from 'Hooks/useFeature';
import { useSpeechRecognitionHook } from 'Hooks/useSpeechRecognitionHook';
import { Skill } from 'Models/Skill';
import { StarterPrompt } from 'Models/StarterPrompt';
import {
    useGetThreadDetailsQuery,
    useGetUserQuery,
    usePatchThreadSkillsMutation,
    usePostAttachLocalFileToThreadMutation,
    usePostMessageMutation,
    usePostMessageRtcMutation,
} from 'Services/API/Aurora';
import {
    updateRightOpen,
    incrementThreadPage,
    initializeThreadPagination,
    disableChat,
    enableChat,
    setToastMessage,
    setIsPostingMessage,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import { readFileAsBase64, validateFile } from 'Utils';

const chatContainerStyles = makeStyles({
    chatContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '0.3fr 2.6fr 0.3fr',
        gridAutoFlow: 'row',
        gridTemplateAreas: `
            "header"
            "chatArea"
            "messageBar"
        `,
        width: '100%',
        justifyContent: 'center', // aligns items horizontally in the center
        alignItems: 'center', // aligns items vertically in the center
    },
    header: {
        ...shorthands.gridArea('header'),
        alignItems: 'center',
    },
    messageBar: {
        ...shorthands.gridArea('messageBar'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '80%',
        justifySelf: 'center',
        paddingRight: '10px',
    },
    chatArea: {
        ...shorthands.gridArea('chatArea'),
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifySelf: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        minWidth: '80%',
        height: '100%',
    },
});

export const Chat: React.FC = () => {
    const canAttachFiles = useFeature(Feature.AttachFiles);
    const [loadingSelectedDocument, setLoadingSelectedDocument] =
        useState(false);
    const scrollAnchorRef: React.LegacyRef<HTMLDivElement> = useRef(null);
    const isCreatingNewThread = useAppSelector(
        (store) => store.thread.isCreatingNewThread,
    );
    const isFetchingMessages = useAppSelector(
        (store) => store.thread.isFetchingMessages,
    );
    const canFetchMoreMessages = useAppSelector(
        (store) => store.thread.canFetchMoreMessages,
    );

    const { data: user } = useGetUserQuery();
    const enableBetaFeatures = user?.beta;

    const {
        record,
        transcript,
        resetTranscript,
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
        toggleRecording,
    } = useSpeechRecognitionHook();

    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const scrollPositionBeforeLoadingMore = useRef<number | null>(0);

    const skillsOpen = useAppSelector(
        (store) => store.userInterface.rightPanelOpen,
    );
    const [isChatInProgress, setIsChatInProgress] = useState<boolean>(false);
    const [chatMessage, setChatMessage] = useState<string>('');
    const toggleResetTranscript = useCallback(() => {
        resetTranscript();
    }, [resetTranscript]);

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // New RTK Query Pattern
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const fetchPageNumber = useAppSelector(
        (store) => store.thread.pagination[threadId ?? 0],
    );

    // initialize pagination for newly loaded thread
    useEffect(() => {
        if (threadId && fetchPageNumber === undefined) {
            updateStore(initializeThreadPagination({ threadId }));
        }
    }, [fetchPageNumber, threadId]);

    const isNewThread = useAppSelector((store) => store.thread.isNewThread);
    const isChatHistoryLimitLoaded = useAppSelector(
        (store) => store.userInterface.isChatHistoryLimitLoaded,
    );
    const isSkillCardsLoaded = useAppSelector(
        (store) => store.userInterface.isSkillCardsLoaded,
    );
    const isMessagesLoading = useAppSelector(
        (store) => store.userInterface.isMessagesLoading,
    );

    const isRetrying = useAppSelector((store) => store.thread.isRetrying);

    const {
        data: selectedThread,
        isFetching: isFetchingDetails,
        isLoading: isLoadingInitialDetails,
    } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [patchThreadSkills, { isLoading: isUpdatingSkills }] =
        usePatchThreadSkillsMutation();

    const [
        postMessage,
        { isLoading: isPostingMessage, status: postMessageStatus },
    ] = usePostMessageMutation();

    const [
        postMessageRtc,
        { isLoading: isPostingMessageRtc, status: postMessageRtcStatus },
    ] = usePostMessageRtcMutation();

    const [attachLocalFile, { isLoading: isUploadingFile }] =
        usePostAttachLocalFileToThreadMutation();

    useEffect(() => {
        if (
            isUpdatingSkills ||
            isPostingMessage ||
            isPostingMessageRtc ||
            isRetrying ||
            isUploadingFile ||
            isFetchingDetails ||
            isMessagesLoading ||
            !isChatHistoryLimitLoaded ||
            !isSkillCardsLoaded
        ) {
            updateStore(disableChat());
            return;
        }
        updateStore(enableChat());
    }, [
        isUpdatingSkills,
        isPostingMessage,
        isUploadingFile,
        isFetchingDetails,
        isPostingMessageRtc,
        isChatHistoryLimitLoaded,
        isSkillCardsLoaded,
        isMessagesLoading,
        isRetrying,
    ]);

    useEffect(() => {
        if (isPostingMessage || isPostingMessageRtc) {
            updateStore(setIsPostingMessage(true));
            return;
        }
        updateStore(setIsPostingMessage(false));
    }, [isPostingMessage, isPostingMessageRtc]);

    const styles = chatContainerStyles();

    // after loading new messages from scrolling, keep scrollbar in the same position
    useEffect(() => {
        if (
            scrollContainerRef.current &&
            scrollPositionBeforeLoadingMore.current
        ) {
            scrollContainerRef.current.scrollTop =
                scrollPositionBeforeLoadingMore.current;
        }
    }, [isFetchingMessages]);

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDragPopoverOpen(false);
            if ((selectedThread?.attachments?.length ?? 0) >= 2) {
                updateStore(
                    setToastMessage({
                        title: ERROR_MESSAGE.FileLimitReached,
                        position: 'bottom',
                    }),
                );
                return;
            }
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                const file = event.dataTransfer.files[0];
                try {
                    validateFile(file.name, selectedThread?.attachments);
                } catch (e) {
                    if (e instanceof Error) {
                        updateStore(
                            setToastMessage({
                                title: e.message,
                                position: 'bottom',
                            }),
                        );
                        return;
                    }
                }
                attachLocalFile({
                    content: await readFileAsBase64(file),
                    contentType: file.type,
                    fileName: file.name,
                    threadId: threadId ?? 0,
                });
            }
        },
        [attachLocalFile, selectedThread?.attachments, threadId],
    );

    // when a new message is sent, scroll to the bottom
    const handleScrollNewMessage = useCallback(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
            scrollPositionBeforeLoadingMore.current = 0;
        }
    }, [scrollContainerRef]);

    useEffect(() => {
        if (
            postMessageStatus === QueryStatus.fulfilled ||
            postMessageRtcStatus === QueryStatus.fulfilled
        ) {
            handleScrollNewMessage();
        }
    }, [postMessageStatus, postMessageRtcStatus, handleScrollNewMessage]);

    // when switching threads, scroll to the bottom
    useEffect(() => {
        handleScrollNewMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId]);

    // event listener for the scroll event to the DrawerBody
    useEffect(() => {
        const currentDrawerBody = scrollContainerRef.current;

        if (currentDrawerBody) {
            currentDrawerBody.removeEventListener('scroll', handleScroll);
            currentDrawerBody.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentDrawerBody) {
                currentDrawerBody.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        threadId,
        isFetchingMessages,
        scrollPositionBeforeLoadingMore,
        scrollContainerRef,
    ]);

    //Helper functions
    //Filters out unchanged skills so no redundant API calls are made
    function filterUnchangedSkills(
        newSkills: SelectedSkillsModel[],
        oldSkills: SelectedSkillsModel[],
    ): SelectedSkillsModel[] {
        return newSkills.filter((newSkill) => {
            const oldSkill = oldSkills.find(
                (oldSkill) => oldSkill.skillid === newSkill.skillid,
            );
            if (oldSkill!.isSelected === newSkill.isSelected) {
                return false;
            }
            return true;
        });
    }
    //Flattens the skills array so it can be sent as a proper setThreadRequestModel array
    const flattenSkills = useCallback(
        (skills: Skill[]): SelectedSkillsModel[] => {
            return skills.reduce((acc: SelectedSkillsModel[], skill) => {
                const flattenedSkill = {
                    skillid: skill.id,
                    isSelected: skill.isSelected,
                } as SelectedSkillsModel;

                if (skill.childSkills) {
                    return acc.concat(
                        flattenedSkill,
                        flattenSkills(skill.childSkills),
                    );
                }
                return acc.concat(flattenedSkill);
            }, []);
        },
        [],
    );

    const sendChatMessage = useCallback(
        (starterMessage?: string) => {
            //If no skills are selected and no files attached, do not send the message
            if (
                !selectedThread?.skills?.some((s) => s.isSelected) &&
                !starterMessage &&
                (selectedThread?.attachments ?? []).length === 0
            ) {
                updateStore(
                    setToastMessage({
                        title: ERROR_MESSAGE.NoSkillsSelected,
                        position: 'bottom',
                    }),
                );
                if (!skillsOpen) {
                    updateStore(updateRightOpen());
                }
                return;
            }

            // const currentMessagesCount = selectedThread!.chatResponses?.length;
            setIsChatInProgress(true);
            setChatMessage('');

            try {
                handleScrollNewMessage();

                if (enableBetaFeatures) {
                    postMessageRtc({
                        threadId: threadId ?? 0,
                        message: starterMessage ?? chatMessage.trim(),
                        retryReferenceId: uuidv4(),
                    });
                } else {
                    postMessage({
                        threadId: threadId ?? 0,
                        message: starterMessage ?? chatMessage.trim(),
                        retryReferenceId: uuidv4(),
                    });
                }

                handleScrollNewMessage();
            } catch (error: any) {
                console.error('error', error);
            } finally {
                setIsChatInProgress(false);
            }
        },
        [
            chatMessage,
            enableBetaFeatures,
            handleScrollNewMessage,
            postMessage,
            postMessageRtc,
            selectedThread?.attachments,
            selectedThread?.skills,
            skillsOpen,
            threadId,
        ],
    );

    const sendStarterMessage = useCallback(
        async (prompt: StarterPrompt) => {
            setIsChatInProgress(true);
            //Create a skills array for updating store
            const newSkillsForStore = selectedThread?.skills?.map((s) => {
                if (s.id === prompt.skillId) {
                    const updatedChildSkills = s.childSkills?.map((cs) => {
                        return { ...cs, isSelected: true };
                    });
                    return {
                        ...s,
                        isSelected: true,
                        childSkills: updatedChildSkills,
                    };
                }
                const updatedChildSkills = s.childSkills?.map((cs) => {
                    return { ...cs, isSelected: false };
                });
                return {
                    ...s,
                    isSelected: false,
                    childSkills: updatedChildSkills,
                };
            });
            //Flatten the skills arrays so it can be filtered easier for API calls
            const newSkillsFlattened: SelectedSkillsModel[] = flattenSkills(
                newSkillsForStore!,
            );
            const oldSkillsFlattened: SelectedSkillsModel[] = flattenSkills(
                selectedThread?.skills ?? [],
            );

            //Filter out the unchanged skills
            const newSkillsForAPI: SelectedSkillsModel[] =
                filterUnchangedSkills(newSkillsFlattened, oldSkillsFlattened);

            //Update the changed skills in the API
            await patchThreadSkills({
                threadId: selectedThread!.id,
                setSkills: newSkillsForAPI,
                setLocalSkills: newSkillsForStore ?? [],
            });
            //Send the message
            sendChatMessage(prompt.promptMessage);
        },
        [flattenSkills, patchThreadSkills, selectedThread, sendChatMessage],
    );

    const showChatsCardsContainer = () => {
        if (isCreatingNewThread) {
            return <LoadNewChatCardsContainer />;
        }
        if (!selectedThread || selectedThread?.messageCount === 0) {
            return (
                <NewChatCardsContainer
                    sendStarter={sendStarterMessage}
                    threadId={threadId}
                />
            );
        }

        if (!isNewThread && (isFetchingMessages || isLoadingInitialDetails)) {
            return <SkeletonChatCards />;
        }

        return <LoadNewChatCardsContainer />;
    };

    const handleMessageChange = useCallback(
        (value: string): void => setChatMessage(value),
        [],
    );

    // show messages container only if the thread details have been fetched
    const showMessageBarContainer = () => {
        const handleEnterKeyPressed = (isShiftKeyPressed: boolean) => {
            if (!isShiftKeyPressed) {
                sendChatMessage();
            }
        };
        const handleLoadingSelectedDocument = (loading: boolean): void =>
            setLoadingSelectedDocument(loading);
        return (
            <div className={styles.messageBar}>
                <Field
                    size="medium"
                    style={{
                        marginTop: '3px',
                        paddingTop: '16px',
                        width: windowWidth < 900 ? '90vw' : '100%',
                        marginLeft: windowWidth < 900 ? '10px' : '0px',
                    }}
                >
                    <MessageBar
                        message={chatMessage}
                        threadId={selectedThread?.id}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        onChange={handleMessageChange}
                        resetTranscript={toggleResetTranscript}
                        transcript={transcript}
                        onEnterKeyPressed={handleEnterKeyPressed}
                        isFetchingThreadDetails={isFetchingDetails}
                        setLoadingSelectedDocument={
                            handleLoadingSelectedDocument
                        }
                        loadingSelectedDocument={loadingSelectedDocument}
                        data-testid="message-bar-component"
                    />
                    <MessageToolBar
                        threadId={selectedThread?.id}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        resetTranscript={toggleResetTranscript}
                        isSendMessageButtonDisabled={
                            isChatInProgress || chatMessage.trim().length === 0
                        }
                        onSendMessageButtonClick={sendChatMessage}
                        loadingSelectedDocument={loadingSelectedDocument}
                    />
                </Field>
            </div>
        );
    };

    // when scroll bar hits the top, load more messages
    const handleScroll = useCallback(() => {
        if (scrollContainerRef.current) {
            var { scrollTop, scrollHeight, clientHeight } =
                scrollContainerRef.current;

            if (Math.abs(scrollTop) + clientHeight + 5 > scrollHeight) {
                // trigger loading more threads
                if (threadId && !isFetchingMessages && canFetchMoreMessages) {
                    scrollPositionBeforeLoadingMore.current =
                        scrollContainerRef.current
                            ? scrollContainerRef.current.scrollTop
                            : null;
                    updateStore(incrementThreadPage({ threadId }));
                }
            }
        }
    }, [canFetchMoreMessages, isFetchingMessages, threadId]);

    const [isDragPopoverOpen, setIsDragPopoverOpen] = useState(false);
    // Need this to prevent a flickering bug (see https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element)
    const enterTarget = useRef<EventTarget | null>(null);

    const handleOnDragOver = useCallback(
        (e: React.DragEvent<HTMLDivElement>): void => e.preventDefault(),
        [],
    );
    const handleOnDragEnter = useCallback(
        (e: React.DragEvent<HTMLDivElement>): void => {
            enterTarget.current = e.target;
            e.preventDefault();
            setIsDragPopoverOpen(true);
        },
        [],
    );
    const handleOnDragLeave = useCallback(
        (e: React.DragEvent<HTMLDivElement>): void => {
            e.preventDefault();
            if (e.target === enterTarget.current) {
                e.preventDefault();
                setIsDragPopoverOpen(false);
            }
        },
        [],
    );
    return (
        <div
            className={styles.chatContainer}
            style={{
                height: windowHeight,
                background: 'var(--colorNeutralBackground5)',
            }}
            onDrop={canAttachFiles ? handleDrop : undefined}
            onDragOver={canAttachFiles ? handleOnDragOver : undefined}
            onDragEnter={canAttachFiles ? handleOnDragEnter : undefined}
            onDragLeave={canAttachFiles ? handleOnDragLeave : undefined}
            data-testid={'chat-container'}
        >
            <ScreenPopover content={<></>} isOpen={isDragPopoverOpen} />
            <div className={styles.header}>
                <BannerContainer />
                <HeaderContainer />
            </div>
            <div
                className={styles.chatArea + ' custom-scrollbar'}
                style={{
                    alignItems: 'flex-end',
                    width: windowWidth < 900 ? '90vw' : '80%',
                    zIndex: 9,
                }}
                ref={scrollContainerRef}
            >
                {selectedThread?.messageCount &&
                !isLoadingInitialDetails &&
                !isCreatingNewThread ? (
                    <ChatMessagesContainer fetchPageNumber={fetchPageNumber} />
                ) : (
                    showChatsCardsContainer()
                )}
                <div ref={scrollAnchorRef}></div>
            </div>
            {showMessageBarContainer()}
            <SettingsMenuContainer />
            {isMicrophonePermissionPopupVisible && (
                <EnableMicrophoneInstructions
                    onPopupClosed={() => {
                        setIsMicrophonePermissionPopupVisible(false);
                    }}
                />
            )}
        </div>
    );
};

import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

export const postErmMessage = (builder: AuroraEndpointBuilder) =>
    builder.mutation<
        { data: ErmChatResponseApiResource },
        PostErmMessageRequest
    >({
        query: (body) => ({
            url: '/v1/Erm/SendErmMessage',
            method: 'POST',
            body,
        }),

        invalidatesTags: ['Threads'], // to update thread title
    });

import {
    Button,
    Radio,
    TableRow,
    tokens,
    Text,
} from '@fluentui/react-components';
import { DeleteFilled, DeleteRegular } from '@fluentui/react-icons';
import { ChangeEvent, useCallback, useState } from 'react';

import { ConfirmDialog } from 'Components/ConfirmDialog';
import { Banner } from 'Models/Banner';
import {
    useDeleteBannerMutation,
    useSetActiveBannerIdMutation,
} from 'Services/API/Aurora';

type Props = {
    banner: { id: number | null; message: string };
    activeBanner?: Banner;
};

export const BannerTableRow = ({ banner, activeBanner }: Props) => {
    const [deleteBanner] = useDeleteBannerMutation();
    const [setActiveBannerId] = useSetActiveBannerIdMutation();
    const [isDeleteHovered, setIsDeleteHovered] = useState(false);
    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
        useState(false);
    const [
        isChangeBannerConfirmDialogOpen,
        setIsChangeBannerConfirmDialogOpen,
    ] = useState(false);

    const isBannerCurrentlyActive = banner && banner?.id === activeBanner?.id;

    // Banner delete handlers
    const handleBannerDeleteClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            setIsDeleteConfirmDialogOpen(true);
        },
        [],
    );
    const handleConfirmBannerDelete = useCallback(() => {
        if (!banner.id) {
            return;
        }
        deleteBanner({ id: banner.id });
        setIsDeleteConfirmDialogOpen(false);
    }, [banner, deleteBanner]);
    const closeDeleteConfirmDialog = () => setIsDeleteConfirmDialogOpen(false);

    // Banner selection handlers
    const handleBannerChangeClick = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            setIsChangeBannerConfirmDialogOpen(true);
        },
        [],
    );
    const handleConfirmBannerChange = useCallback(() => {
        setActiveBannerId({
            id: banner.id,
            message: banner.message,
        });
        setIsChangeBannerConfirmDialogOpen(false);
    }, [banner, setActiveBannerId]);
    const closeChangeBannerConfirmDialog = () =>
        setIsChangeBannerConfirmDialogOpen(false);

    return (
        <>
            <ConfirmDialog
                dialogOpen={isDeleteConfirmDialogOpen}
                handleConfirmClick={
                    isBannerCurrentlyActive
                        ? closeDeleteConfirmDialog
                        : handleConfirmBannerDelete
                }
                handleCancelClick={
                    isBannerCurrentlyActive
                        ? undefined
                        : closeDeleteConfirmDialog
                }
                text={
                    isBannerCurrentlyActive
                        ? 'Cannot delete active banner. Select another banner or "None" and try again.'
                        : 'Are you sure you want to permanently delete this banner?'
                }
            />
            <ConfirmDialog
                dialogOpen={isChangeBannerConfirmDialogOpen}
                handleConfirmClick={handleConfirmBannerChange}
                handleCancelClick={closeChangeBannerConfirmDialog}
                text="This will change the live banner, are you sure you want to continue?"
            />
            <TableRow style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Radio
                        value={banner.id?.toString() ?? 'none'}
                        label={
                            <>
                                {banner.message}
                                {banner.id &&
                                activeBanner?.id?.toString() ===
                                    banner.id?.toString() ? (
                                    <Text
                                        style={{
                                            color: tokens.colorBrandForeground1,
                                        }}
                                    >
                                        {' '}
                                        (Live)
                                    </Text>
                                ) : (
                                    ''
                                )}
                            </>
                        }
                        onChange={(event) => handleBannerChangeClick(event)}
                        style={{ width: '80%' }}
                    />
                    <Button
                        icon={
                            isDeleteHovered ? (
                                <DeleteFilled />
                            ) : (
                                <DeleteRegular />
                            )
                        }
                        onMouseEnter={() => setIsDeleteHovered(true)}
                        onMouseLeave={() => setIsDeleteHovered(false)}
                        onClick={(event) => handleBannerDeleteClick(event)}
                        style={{
                            padding: '5px',
                            border: 'none',
                            background: 'transparent',
                        }}
                    />
                </div>
            </TableRow>
        </>
    );
};

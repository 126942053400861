import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Card,
    CardHeader,
    Image,
    Button,
} from '@fluentui/react-components';
import { ArrowRightRegular } from '@fluentui/react-icons';
import 'Styles/ChatCards.Module.css';
import { useState } from 'react';

import { MarkdownViewer } from 'Components/MarkdownViewer';
import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

const useStyles = makeStyles({
    cardLinks: {
        marginRight: '20px',
        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '100%',

        backgroundColor: tokens.colorNeutralBackgroundStatic,

        ':hover': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
            cursor: 'pointer',
        },
        marginBottom: '7px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    card: {
        paddingTop: '0px',
        paddingBottom: '0px',
        marginRight: '20px',
        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '200px',
        backgroundColor: '#202F2F',
        marginBottom: '20px',
    },
    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center',
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },
});

type Props = {
    chatResponse: ErmChatResponseApiResource;
    userMessage: PostErmMessageRequest;
};
export const ErmLinksRecipe: React.FC<Props> = (props: Props) => {
    const styles = useStyles();
    const [itemsToShow, setItemsToShow] = useState(5);

    const response = props.chatResponse;
    if (response.response === null) {
        return null;
    }
    const ermLinks = response.references;

    // Function to handle the load more button click
    const handleLoadMore = () => {
        setItemsToShow(itemsToShow + 5);
    };

    // Remove duplicate items from the list
    const uniqueErmLinks = ermLinks?.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t.url === item.url),
    );
    return (
        <div
            style={{
                width: '85%',
                marginBottom: '30px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <Image
                    alt="Aurora Logo"
                    src="/logo192.png"
                    shape="circular"
                    height={50}
                    width={50}
                    style={{ marginRight: '10px' }}
                />
                <Card
                    className={styles.card}
                    style={{
                        borderRadius: 5,
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }}
                >
                    <MarkdownViewer
                        content={response.response}
                    ></MarkdownViewer>
                </Card>
            </div>
            <div>
                <p
                    style={{
                        marginLeft: '80px',
                    }}
                >
                    Category Name
                </p>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '5px 20px', // Adjust the gap between cards as needed
                        paddingLeft: '50px',
                    }}
                >
                    {/* Links */}
                    {uniqueErmLinks &&
                        uniqueErmLinks
                            .slice(0, itemsToShow)
                            .map((ermLinkItem, index) => (
                                <>
                                    <Card
                                        className={styles.cardLinks}
                                        style={{
                                            borderRadius: '40px',
                                            borderColor:
                                                tokens.colorNeutralBackground4,
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (ermLinkItem.url) {
                                                window.open(
                                                    ermLinkItem.url,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                );
                                            }
                                        }}
                                        key={index}
                                    >
                                        <CardHeader
                                            header={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between', // Pushes the icon to the far right
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                '/PublixLogo.png'
                                                            }
                                                            alt="File icon"
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                marginRight:
                                                                    '10px',
                                                                marginLeft:
                                                                    '15px',
                                                            }}
                                                        />
                                                        <Text
                                                            style={{
                                                                marginTop:
                                                                    '5px',
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                        >
                                                            <b>
                                                                {
                                                                    ermLinkItem.title
                                                                }
                                                            </b>
                                                        </Text>
                                                    </div>
                                                    <ArrowRightRegular
                                                        style={{
                                                            marginRight: '20px',
                                                            height: '15px',
                                                            width: '15px',
                                                        }}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Card>
                                </>
                            ))}
                </div>
                {uniqueErmLinks && itemsToShow < uniqueErmLinks.length && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <Button
                            style={{
                                padding: '10px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                borderRadius: '10px',
                                backgroundColor:
                                    tokens.colorNeutralBackgroundStatic,
                            }}
                            onClick={handleLoadMore}
                        >
                            Load More
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

import { Switch, Text } from '@fluentui/react-components';
import React, { useCallback } from 'react';

import { Skill } from 'Models/Skill';

type ChildSkillProps = {
    skillData: Skill;
    handleChildSkillSelection: (
        id: number,
        skillName: string,
        isChild: boolean,
    ) => void;
};

export const ChildSkill: React.FC<ChildSkillProps> = (props) => {
    const handleSwitchChange = useCallback(() => {
        props.handleChildSkillSelection(
            props.skillData.id,
            props.skillData.name,
            true,
        );
    }, [props]);

    return (
        <div className="ChildSkillContainer">
            <Text>{props.skillData.name}</Text>
            <Switch
                checked={props.skillData.isSelected}
                data-testid={`child-skill-switch-${props.skillData.name}`}
                onChange={handleSwitchChange}
            ></Switch>
        </div>
    );
};

import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Card,
    CardHeader,
    Image,
    Caption1,
} from '@fluentui/react-components';
import { ArrowRightRegular } from '@fluentui/react-icons';

import 'Styles/ChatCards.Module.css';
import { MarkdownViewer } from 'Components/MarkdownViewer';
import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

const useStyles = makeStyles({
    scrollContainer: {
        display: 'flex',
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    cardLinks: {
        marginRight: '20px',
        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '100%',

        backgroundColor: tokens.colorNeutralBackgroundStatic,

        ':hover': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            cursor: 'pointer',
        },
        marginBottom: '7px',
    },
    card: {
        marginRight: '20px',
        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '200px',
        paddingBottom: '10px',
        backgroundColor: tokens.colorNeutralBackground1Pressed,
        marginBottom: '20px',
    },
    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center',
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },

    skillName: {
        ...shorthands.margin(0),
        justifyContent: 'flex-end',
    },
    horizontalCardImage: {
        width: '64px',
        height: '64px',
    },
});

type Props = {
    chatResponse: ErmChatResponseApiResource;
    userMessage: PostErmMessageRequest;
};
export const ErmLinksRegular: React.FC<Props> = (props: Props) => {
    const styles = useStyles();
    const response = props.chatResponse;
    if (response.response === null) {
        return null;
    }

    const ermLinks = response.references;
    const uniqueErmLinks = ermLinks?.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t.url === item.url),
    );
    return (
        <div
            style={{
                width: '85%',
                marginBottom: '30px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <Image
                    alt="Aurora Logo"
                    src="/logo192.png"
                    shape="circular"
                    height={50}
                    width={50}
                    style={{ marginRight: '10px' }}
                />
                <Card
                    className={styles.card}
                    style={{
                        borderRadius: 5,
                        paddingLeft: '20px',
                        width: '100%',
                    }}
                >
                    <MarkdownViewer
                        content={response.response}
                    ></MarkdownViewer>
                    <Caption1
                        align="end"
                        style={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '10px',
                        }}
                    ></Caption1>
                </Card>
            </div>
            <div style={{ paddingLeft: '55px' }}>
                {uniqueErmLinks &&
                    uniqueErmLinks.slice(0, 5).map((ermLinkItem, index) => (
                        <>
                            <Card
                                className={styles.cardLinks}
                                style={{
                                    borderRadius: '15px',
                                    borderColor: tokens.colorNeutralBackground4,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (ermLinkItem.url) {
                                        window.open(
                                            ermLinkItem.url,
                                            '_blank',
                                            'noopener,noreferrer',
                                        );
                                    }
                                }}
                                key={index}
                            >
                                <CardHeader
                                    header={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        padding: '10px',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                        marginRight: '20px',
                                                        marginLeft: '10px',
                                                        backgroundColor:
                                                            tokens.colorNeutralBackground1Hover,
                                                        borderRadius: '0px',
                                                    }}
                                                >
                                                    {index + 1}
                                                </Text>

                                                <Text>
                                                    <b>{ermLinkItem.title}</b>
                                                </Text>
                                            </div>
                                            <ArrowRightRegular
                                                style={{
                                                    marginRight: '20px',
                                                    height: '15px',
                                                    width: '15px',
                                                }}
                                            />
                                        </div>
                                    }
                                />
                            </Card>
                        </>
                    ))}
            </div>
        </div>
    );
};

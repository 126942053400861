import { ErrorMessage } from './Models/ErrorMessage';

export enum RequestHeaders {
    Authorization = 'Authorization',
    ContentType = 'Content-Type',
    SessionId = 'x-aur-session-id',
    RequestId = 'x-aur-client-request-id',
}

export const ERROR_REPORTING_EMAIL = 'aiic@publix.com';

export const ENCODED_USER_ERROR_SUBJECT = encodeURIComponent(
    'AuroraWeb User Error Report',
);

export const ENCODED_USER_ERROR_BODY = (errorMessage: ErrorMessage) =>
    encodeURIComponent(
        `RequestId: ${errorMessage?.requestId}
    
Please provide any relevant details:

`,
    );

export const ERROR_MESSAGE = {
    FileLimitReached: 'You cannot upload more than 2 files.',
    Reaction: 'Error submitting reaction. Please try again.',
    ThreadUpdate: 'Error updating thread. Please try again.',
    FileUpload: 'Error uploading file. Please try again.',
    FileRemove: 'Error removing attached file. Please try again.',
    FileDownload: 'Error downloading file. Please try refreshing the page.',
    ThreadCreate: 'Error creating new thread. Please try again.',
    ThreadsGet: 'Error retrieving threads. Please try refreshing the page.',
    ThreadDetailsGet:
        'Error retrieving thread details. Please try refreshing the page.',
    ThreadMessagesGet:
        'Error retrieving thread messages. Please try refreshing the page.',
    ThreadDelete: 'Error deleting thread. Please try again.',
    ContextUpdate: 'Error updating context. Please try again.',
    SkillsUpdate: 'Error updating skills. Please try again.',
    MessagePost: 'Error submitting message, please try again.',
    NoSkillsSelected: 'You must select at least 1 skill on the right.',
    TryAgain: 'Please try again.',
    BannersGet: 'Error fetching banners. Please try refreshing the page.',
    BannersUpdate: 'Error updating banner. Please try again.',
    BannersDelete: 'Error deleting banner. Please try again.',
    UserGet: 'Error fetching user information. Please try refreshing the page.',
    UserUpdate: 'Error updating user information. Please try again.',
    SpeechTokenGet: 'Error fetching speech token. Please try again.',
};

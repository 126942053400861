import { unionBy } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { GetThreadsResponse } from 'Services/API/Aurora/dto';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getThreads = (builder: AuroraEndpointBuilder) =>
    builder.query<GetThreadsResponse, number>({
        query: (pageNumber) =>
            `/v1/Threads/GetThreads?PageNumber=${pageNumber}`,
        transformResponse: (response: { data: GetThreadsResponse }) => {
            return response.data;
        },
        transformErrorResponse: (response: { status: string | number }) => {
            return response.status;
        },
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => {
            // currentCache.items.push(...newItems.items);
            return {
                ...newItems,
                items: unionBy(newItems.items, currentCache.items, 'id'),
            };
        },
        forceRefetch({ currentArg, previousArg }) {
            return currentArg !== previousArg;
        },
        async onQueryStarted(_pageNumber, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadsGet,
                        body: '',
                        position: 'top-start',
                    }),
                );
            }
        },

        providesTags: () => [{ type: 'Threads' }], // TODO: do we want this?
    });

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { auroraApi } from 'Services/API/Aurora';
import { reducer } from 'Services/StateManagement/Reducers';
import { rtkQueryErrorLogger } from 'Utils/logging';

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            auroraApi.middleware,
            rtkQueryErrorLogger,
        ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import {
    DrawerBody,
    DrawerHeader,
    InlineDrawer,
    Button,
    tokens,
    DrawerHeaderTitle,
    Drawer,
    DrawerProps,
} from '@fluentui/react-components';
import { Compose28Regular } from '@fluentui/react-icons';
import { debounce } from 'lodash';
import React, { useRef, useEffect, useState, useCallback } from 'react';

import { ThreadItemContainer } from 'Components/ThreadItemContainer';
import { UserProfile } from 'Containers/UserProfileContainer';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useIntersection } from 'Hooks/useIntersection';
import {
    useGetThreadDetailsQuery,
    useGetThreadsQuery,
    usePostThreadMutation,
} from 'Services/API/Aurora';
import {
    updateLeftOpen,
    unselectThreadId,
    setSelectedThreadId,
    setIsCreatingNewThread,
    setIsNewThread,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

import 'Styles/Styles.css';

type DrawerType = Required<DrawerProps>['type'];

export const LeftSidebar: React.FC = () => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    const [type, setType] = React.useState<DrawerType>('inline');
    const userInterface = useAppSelector((store) => store.userInterface);
    const [pageNumber, setPageNumber] = useState(1);

    // New RTK Pattern
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data: threads, isLoading: isLoadingThreads } =
        useGetThreadsQuery(pageNumber);
    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const isChatDisabled = useAppSelector(
        (store) => store.userInterface.isChatDisabled,
    );

    const isNewChatButtonDisabled =
        isChatDisabled ||
        (Boolean(selectedThread) && selectedThread?.messageCount === 0);

    const [
        postThread,
        { data: postThreadResult, isLoading: isCreatingNewThread },
    ] = usePostThreadMutation();
    useEffect(() => {
        if (postThreadResult) {
            updateStore(setSelectedThreadId(postThreadResult.id));
        }
    }, [postThreadResult]);

    // // Create new thread after thread list first loads
    useEffect(() => {
        if (!isLoadingThreads) {
            postThread();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingThreads]);

    useEffect(() => {
        updateStore(setIsCreatingNewThread(isCreatingNewThread));
    }, [isCreatingNewThread]);

    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleClickOutside = useCallback((event: MouseEvent) => {
        // Check if the screen width is less than or equal to 900 pixels
        if (window.innerWidth <= 900) {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target as Node)
            ) {
                // If the click is outside the sidebar, dispatch an action to close it
                updateStore(updateLeftOpen());
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleNewChatButton = useCallback(() => {
        // updateStore(initializeThreadDetailsAction());
        updateStore(unselectThreadId());
        updateStore(setIsNewThread(true));
        postThread();
    }, [postThread]);

    const onMediaQueryChange = React.useCallback(
        ({ matches }: { matches: boolean }) =>
            setType(matches ? 'overlay' : 'inline'),
        [setType],
    );

    React.useEffect(() => {
        const match = window.matchMedia('(max-width: 900px)');

        if (match.matches) {
            setType('overlay');
        }

        match.addEventListener('change', onMediaQueryChange);

        return () => match.removeEventListener('change', onMediaQueryChange);
    }, [onMediaQueryChange]);

    const drawerBodyRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const isBottomVisible = useIntersection(bottomRef, '0px');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const increasePageNumber = useCallback(
        debounce(() => setPageNumber((prev) => prev + 1), 300),
        [],
    );

    useEffect(() => {
        if (
            isBottomVisible &&
            threads?.pageCount &&
            pageNumber < threads?.pageCount &&
            selectedThread
        ) {
            increasePageNumber(); // Trigger a function when the div is visible on view port
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        increasePageNumber,
        isBottomVisible,
        threads?.pageCount,
        selectedThread,
    ]);

    return (
        <Drawer
            type={type}
            separator
            position="start"
            ref={sidebarRef}
            open={userInterface.leftPanelOpen}
            style={{
                height: 'windowHeight',
                background: tokens.colorNeutralBackground3,
                width: type == 'overlay' ? '80%' : '20%', // can change size of drawer
                overflow: 'visible', // needs this otherwise the main chat area will be on top
                minWidth: '20%',
            }}
            data-testid="left-sidebar-container"
        >
            <InlineDrawer
                separator
                position={'start'}
                open={true}
                style={{
                    background: tokens.colorNeutralBackground3,
                    height: windowHeight,
                    width: '100%',
                }}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle></DrawerHeaderTitle>
                    <Button
                        icon={<Compose28Regular />}
                        onClick={handleNewChatButton}
                        style={{
                            padding: '8px',
                            width: '100%',
                            background: tokens.colorNeutralBackground3,
                        }}
                        disabled={isNewChatButtonDisabled}
                        data-testid="new-chat-button"
                    >
                        New Chat
                    </Button>
                </DrawerHeader>

                <DrawerBody
                    className="custom-scrollbar"
                    style={{ background: 'transparent', marginBottom: '55px' }}
                    ref={drawerBodyRef}
                >
                    <div
                        style={{
                            marginTop: '50px',
                            alignSelf: 'flex-start',
                            width: '-webkit-fill-available',
                        }}
                    >
                        <ThreadItemContainer pageNumber={pageNumber} />
                        <div ref={bottomRef} />
                    </div>
                    <div className="LeftSidebarFooter">
                        <UserProfile />
                    </div>
                </DrawerBody>
            </InlineDrawer>
        </Drawer>
    );
};

import { ERROR_MESSAGE } from 'Constants';
import { ChatThreadModel } from 'Models/ChatThread';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import {
    setIsNewThread,
    setSelectedThreadId,
    setToastMessage,
} from 'Services/StateManagement/Actions';

export const postThread = (builder: AuroraEndpointBuilder) =>
    builder.mutation<ChatThreadModel, void>({
        query: () => ({
            url: '/v1/Threads/CreateThread',
            method: 'POST',
        }),
        transformResponse: (response: { data: ChatThreadModel }) => {
            return response.data;
        },
        async onQueryStarted(_pageNumber, { dispatch, queryFulfilled }) {
            // Optimistically updates the chat message cache with the new user message
            const TEMP_ID = 0 - new Date().getTime();
            const postResult = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreads',
                    0,
                    (draft: { items: ChatThreadModel[] }) => {
                        const newMessage: ChatThreadModel = {
                            id: TEMP_ID,
                            title: 'New Chat',
                            isFavorite: false,
                            timeStamp: new Date().toISOString(),
                            messageCount: 1,
                            isHidden: true,
                        };
                        draft.items.unshift(newMessage);
                    },
                ),
            );

            try {
                const result = await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData('getThreads', 0, (draft) => {
                        const newThread = draft.items.find(
                            (item) => item.id === TEMP_ID,
                        );
                        if (newThread) {
                            newThread.id = result.data.id;
                        }
                    }),
                );
                dispatch(
                    auroraApi.util.upsertQueryData(
                        'getThreadMessages',
                        { threadId: result.data.id, pageNumber: 1 },
                        {
                            items: [],
                            totalItemCount: 0,
                            pageCount: 1,
                            pageNumber: 1,
                            pageSize: 25,
                            hasNextPage: false,
                            hasPreviousPage: false,
                            isFirstPage: true,
                            isLastPage: true,
                        },
                    ),
                );
                dispatch(setSelectedThreadId(result.data.id));
                dispatch(setIsNewThread(true));
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadCreate,
                        body: '',
                        position: 'top',
                    }),
                );
                // undoes the optimistic update if the call fails
                postResult.undo();
                /**
                 * Alternatively, on failure you can invalidate the corresponding cache tags
                 * to trigger a re-fetch:
                 * dispatch(api.util.invalidateTags(['Messages']))
                 */
            }
        },
    });

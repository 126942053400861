import { RequestMethods } from 'Helpers/WebRequestHelper';
import { useAuroraApi } from 'Hooks/useAuroraApi';
import { Banner, BannerModelSchema } from 'Models/Banner';
import { Terms } from 'Models/Terms';
import {
    updateTerms,
    updateTermsPopoverTrue,
    updateTermsPopoverFalse,
    updateBanner,
    updateBannerIsHidden,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import { validateDataAgainstSchema } from 'Utils';

//Helper for validating schema
const checkForInvalidData = (data: Banner | null): void => {
    if (!data) {
        throw new Error('Data is null');
    }
    // Validate the data against the schema
    const isValidSchema = validateDataAgainstSchema<Banner>(
        data,
        BannerModelSchema,
    );
    // If the data does not match the schema, throw an error
    if (!isValidSchema) {
        throw new Error('Data does not match schema');
    }
};

export const useTermsAndConditions = () => {
    //Helper for API calls
    const { processApiRequest } = useAuroraApi();
    //Get User
    const getTerms = async () => {
        // make API call to fetch the threads.

        try {
            const response = await processApiRequest({
                //todo: update the path to the correct endpoint.
                path: '/v1/TermsConditions/GetTerms',
            });
            const data = response.data.data as Terms;
            // update Terms in store.
            updateStore(updateTerms(data));
            if (data && data.title !== '' && data.message !== '') {
                updateStore(updateTermsPopoverTrue());
            }
            // updateStore(updateTermsPopoverTrue());
        } catch (error) {
            console.error(
                'Error occurred while processing API request: getTerms',
                error,
            );
            // throw error;
        }
    };

    const getBanner = async () => {
        //Make API call to fetch the banner
        try {
            const response = await processApiRequest({
                path: '/v1/TermsConditions/GetBanner',
            });
            const data = response.data.data as Banner;
            //Validate the data against the schema, and update the store
            if (data) {
                checkForInvalidData(data);
                updateStore(updateBanner(data));
            }
        } catch (error) {
            console.error(
                'Error occurred while processing API request: getBanner',
                error,
            );
        }
    };

    const acceptTerms = async (termId: number) => {
        try {
            const response = await processApiRequest({
                //todo: update the path to the correct endpoint.
                path: '/v1/TermsConditions/AcceptTerms?termId=' + termId,
                method: RequestMethods.POST,
            });
            const data = {
                id: 0,
                message: '',
                title: '',
            };
            // checkForInvalidData(data);
            // update Terms in store.
            updateStore(updateTerms(data));

            updateStore(updateTermsPopoverFalse());

            // handle the response here
        } catch (error) {
            console.error(
                'Error occurred while processing API request: acceptTerms',
                error,
            );
            // throw error;
        }
    };

    const hideBanner = async (bannerId: number | null) => {
        if (!bannerId) {
            return;
        }
        try {
            const response = await processApiRequest({
                path: '/v1/TermsConditions/HideBanner?bannerId=' + bannerId,
                method: RequestMethods.POST,
            });
            updateStore(updateBannerIsHidden(true));
        } catch (error) {
            console.error(
                'Error occurred while processing API request: hideBanner',
                error,
            );
        }
    };

    const showBanner = async (bannerId: number | null) => {
        if (!bannerId) {
            return;
        }
        try {
            await processApiRequest({
                path: '/v1/TermsConditions/ShowBanner?bannerId=' + bannerId,
                method: RequestMethods.POST,
            });
            updateStore(updateBannerIsHidden(false));
        } catch (error) {
            console.error(
                'Error occurred while processing API request: showBanner',
                error,
            );
        }
    };

    return { getTerms, getBanner, acceptTerms, hideBanner, showBanner };
};

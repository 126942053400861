import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { Role } from 'Config/features';
import { acquireToken } from 'Helpers/MsalHelper';

export const useRole = (): Role => {
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const [role, setRole] = useState(Role.Retail);

    useEffect(() => {
        async function fetchAdminTokenAndSetRole() {
            const token = await acquireToken(instance, account, [
                process.env.REACT_APP_API_REQUEST_SCOPE!,
            ]);
            if (!token) {
                return;
            }
            const decoded = jwtDecode<{ roles: string[] }>(token);
            if (decoded.roles.includes('Aurora.Admins')) {
                setRole(Role.Admin);
                return;
            }
            if (decoded.roles.every((role) => role.endsWith('Retail.Users'))) {
                setRole(Role.Retail);
                return;
            }
            setRole(Role.Support);
        }
        fetchAdminTokenAndSetRole();
    }, [instance, account]);

    return role;
};

import {
    createDarkTheme,
    createLightTheme,
    teamsDarkTheme,
    teamsLightTheme,
    teamsHighContrastTheme,
} from '@fluentui/react-components';
import type { BrandVariants, Theme } from '@fluentui/react-components';

export const publixTheme: BrandVariants = {
    10: '#020401',
    20: '#091A03',
    30: '#123406',
    40: '#1E3C13',
    50: '#1B4D08',
    60: '#275915',
    70: '#2A6815',
    80: '#2D810E',
    90: '#3A861F',
    100: '#53933A',
    110: '#579A3E',
    120: '#81B36E',
    130: '#93BA81',
    140: '#ABCD9F',
    150: '#D5E6CF',
    160: '#EAF3E7',
};

export const LightTheme: Theme = {
    ...createLightTheme(publixTheme),
};

export const DarkTheme: Theme = {
    ...createDarkTheme(publixTheme),
};

export const TeamsDarkTheme: Theme = {
    ...teamsDarkTheme,
};

export const TeamsLightTheme: Theme = {
    ...teamsLightTheme,
};

export const TeamsHighContrastTheme: Theme = {
    ...teamsHighContrastTheme,
};

DarkTheme.colorBrandForeground1 = publixTheme[110]; // use brand[110] instead of brand[100]
DarkTheme.colorBrandForeground2 = publixTheme[120]; // use brand[120] instead of brand[110]
DarkTheme.colorNeutralBackground2 = '#1F1F1F'; // use brand[20] instead of brand[10]
DarkTheme.colorNeutralBackground3 = '#141414';
DarkTheme.colorNeutralBackground4 = 'rgba(20, 20, 20, .5)'; // This is used for the New Chat Screen buttons
LightTheme.colorNeutralBackground1 = '#EDEDED';
LightTheme.colorNeutralBackground3Hover = '#D9D9D9';
DarkTheme.colorNeutralBackground3Hover = '#3B3B3B';
DarkTheme.colorNeutralBackground5 =
    'linear-gradient(90deg, rgba(28,34,34,1) 0%, rgba(23,33,33,1) 40%,  rgba(23,33,33,1) 60%,  rgba(28,34,34,1) 100%)';
LightTheme.colorNeutralBackground5 =
    'linear-gradient(90deg, rgba(224,229,229,1) 0%, rgba(200,210,210,1) 40%,  rgba(200,210,210,1) 60%,  rgba(224,229,229,1) 100%)';

DarkTheme.colorNeutralBackground6 = 'rgba(0,51,51,1)';
LightTheme.colorNeutralBackground6 = 'rgba(0,51,51,.2)';
// DarkTheme.colorNeutralBackground5Hover = 'rgba(20,20,20,.5)';
DarkTheme.colorNeutralBackgroundStatic = '#1D2828';
LightTheme.colorNeutralBackgroundStatic = 'rgba(224,229,229,1)';
DarkTheme.colorNeutralBackground1Pressed = '#202F2F';
LightTheme.colorNeutralBackground1Pressed = '#EDEDED';
DarkTheme.colorNeutralBackground1Hover = '#334646';
LightTheme.colorNeutralBackground1Hover = '#B1C3C3';

import { useMsal } from '@azure/msal-react';

import { getProfilePicture } from 'Helpers/GraphApiHelper';
import { acquireToken, msalSignOut } from 'Helpers/MsalHelper';
import { updateUserProfilePictureAction } from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

export const useUserProfile = () => {
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const getProfilePictureUrl = async (): Promise<void> => {
        try {
            const accessToken = await acquireToken(
                instance,
                account,
                ['User.Read'],
                false,
            );
            const result = await getProfilePicture(accessToken!);
            const { URL, webkitURL } = window;
            const url = URL || webkitURL;
            let profilePictureUrl = url.createObjectURL(result);
            updateStore(updateUserProfilePictureAction(profilePictureUrl));
        } catch (error) {
            console.error('error in getting profile picture url', error);
        }
    };

    const signOut = async (): Promise<void> => {
        await msalSignOut(instance);
    };

    return {
        getProfilePictureUrl,
        signOut,
    };
};

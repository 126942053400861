import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Skill } from 'Models/Skill';

const initialState: Skill[] = [];

export const UserSkillSlice = createSlice({
    name: 'userSkills',
    initialState: initialState,
    reducers: {
        updateUserSkills: (state, action: PayloadAction<Skill[]>) => {
            return action.payload;
        },
    },
});

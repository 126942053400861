import { ChatThreadModel } from 'Models/ChatThread';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import {
    setIsNewThread,
    setSelectedThreadId,
} from 'Services/StateManagement/Actions';

export const postErmThread = (builder: AuroraEndpointBuilder) =>
    builder.mutation<ChatThreadModel, void>({
        query: () => ({
            url: '/v1/Erm/CreateErmThread',
            method: 'POST',
        }),
        transformResponse: (response: { data: ChatThreadModel }) => {
            return response.data;
        },
        async onQueryStarted(_pageNumber, { dispatch, queryFulfilled }) {
            // Optimistically updates the chat message cache with the new user message
            const TEMP_ID = 0 - new Date().getTime();
            try {
                const result = await queryFulfilled;
                dispatch(setSelectedThreadId(result.data.id));
                dispatch(setIsNewThread(true));
            } catch {
                // undoes the optimistic update if the call fails
                console.info('Failed to create new ERM thread');
                /**
                 * Alternatively, on failure you can invalidate the corresponding cache tags
                 * to trigger a re-fetch:
                 * dispatch(api.util.invalidateTags(['Messages']))
                 */
            }
        },
    });

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Checkbox,
    CheckboxOnChangeData,
} from '@fluentui/react-components';
import { useState } from 'react';

import { MarkdownViewer } from 'Components/MarkdownViewer';
import { useTermsAndConditions } from 'Hooks/useAlerts';
import { useAppSelector } from 'Hooks/useAppSelector';
import { Terms } from 'Models/Terms';
import { updateTermsPopoverFalse } from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

export const TermsAndConditions: React.FC = () => {
    const [checked, setChecked] = useState(false);
    const handleChange = (
        ev: React.ChangeEvent<HTMLInputElement>,
        data: CheckboxOnChangeData,
    ) => {
        setChecked(Boolean(data.checked));
    };
    const terms: Terms = useAppSelector((store) => store.terms);
    const termsPopover = useAppSelector(
        (store) => store.userInterface.termsPopover,
    );
    const { acceptTerms } = useTermsAndConditions();

    let formattedText = terms.message.replace(/\\n\\n/g, '\n\n');
    return (
        <>
            <Dialog modalType="non-modal" open={termsPopover}>
                <DialogSurface>
                    <DialogBody style={{ maxHeight: '75vh' }}>
                        <DialogTitle
                            action={null}
                            style={{
                                textAlign: 'center',
                                fontSize: '12px',
                                marginBottom: '0px',
                                paddingBottom: '0px',
                            }}
                        >
                            <MarkdownViewer content={terms.title} />
                        </DialogTitle>
                        <DialogContent className="custom-scrollbar">
                            <MarkdownViewer content={formattedText} />
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                label="I have read and agree to the terms of service"
                            />
                            <DialogActions
                                style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <DialogTrigger disableButtonEnhancement>
                                    <Button
                                        disabled={!checked}
                                        appearance="primary"
                                        style={{
                                            width: '100%',
                                            marginTop: '8px',
                                        }}
                                        onClick={() => {
                                            acceptTerms(terms.id).then(() => {
                                                updateStore(
                                                    updateTermsPopoverFalse(),
                                                );
                                            });
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

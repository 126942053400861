import { ERROR_MESSAGE } from 'Constants';
import { Skill } from 'Models/Skill';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getUserSkills = (builder: AuroraEndpointBuilder) =>
    builder.query<Skill[], void>({
        query: () => '/v1/UserSkills/GetUserSkills',
        transformResponse: (response: { data: Skill[] }) => {
            return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.UserGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
        providesTags: ['UserSkills'],
    });

import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';

export const createBanner = (builder: AuroraEndpointBuilder) =>
    builder.mutation<
        { data: { id: number; message: string; isActive: boolean } },
        { message: string }
    >({
        query: (body) => ({
            url: '/v1/TermsConditions/CreateBanner',
            method: 'POST',
            body,
        }),

        async onQueryStarted({ message }, { dispatch, queryFulfilled }) {
            const TEMP_ID = new Date().getTime();
            dispatch(
                auroraApi.util.updateQueryData('getBanners', 1, (draft) => {
                    draft.items = [
                        {
                            message,
                            id: TEMP_ID,
                            isActive: false,
                        },
                        ...draft.items,
                    ];
                }),
            );
            try {
                const result = await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData('getBanners', 1, (draft) => {
                        const newBanner = draft.items.find(
                            (b) => b.id === TEMP_ID,
                        );
                        if (newBanner) {
                            newBanner.id = result.data.data.id;
                        }
                    }),
                );
            } catch (e) {
                dispatch(auroraApi.util.invalidateTags(['Banners']));
            }
        },
        invalidatesTags: ['Banners'],
    });

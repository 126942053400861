import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Checkbox,
    makeStyles,
    tokens,
    Spinner,
} from '@fluentui/react-components';
import { useState, useEffect } from 'react';

import {
    useDeleteAllThreadsMutation,
    usePostThreadMutation,
} from 'Services/API/Aurora';
import {
    updateSettingsDialogOpen,
    unselectThreadId,
    setIsNewThread,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

interface ClearThreadsDialogProps {
    isClearThreadsDialogOpen: boolean;
    setIsClearThreadsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
    deleteButton: {
        backgroundColor: tokens.colorPaletteRedBackground3,
        '&:hover': {
            backgroundColor: tokens.colorPaletteRedForeground1,
            color: 'white',
        },
    },
});

export const ClearThreadsDialog: React.FC<ClearThreadsDialogProps> = ({
    isClearThreadsDialogOpen,
    setIsClearThreadsDialogOpen,
}) => {
    const styles = useStyles();
    const [isIncludeFavoritesChecked, setIsChecked] = useState(false);
    const [deleteAllThreads, { isLoading, isSuccess }] =
        useDeleteAllThreadsMutation();

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIsChecked(event.target.checked);
    };
    const handleCancel = () => {
        updateStore(updateSettingsDialogOpen());
        setIsClearThreadsDialogOpen(false);
    };
    const handleSubmit = async () => {
        try {
            await deleteAllThreads({
                includeFavorites: isIncludeFavoritesChecked,
            });
        } catch (e) {
            console.error('failed to delete all threads', e);
        }
    };
    useEffect(() => {
        if (!isLoading && isSuccess) {
            updateStore(updateSettingsDialogOpen());
            setIsClearThreadsDialogOpen(false);
            updateStore(unselectThreadId());
            updateStore(setIsNewThread(true));
            postThread();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isSuccess]);

    const [
        postThread,
        { data: postThreadResult, isLoading: isCreatingNewThread },
    ] = usePostThreadMutation();

    return (
        <Dialog open={isClearThreadsDialogOpen}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <div className="ClearThreadsDialogContent">
                            <label>Deleting threads is irreversible</label>
                            <Checkbox
                                label="Include Favorited Threads"
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            className={styles.deleteButton}
                            appearance="primary"
                            onClick={() => handleSubmit()}
                        >
                            {isLoading ? (
                                <Spinner size={'extra-tiny'} />
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const patchThread = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { id: number; title: string; isFavorite: boolean }>({
        query: (body) => ({
            url: '/v1/Threads/UpdateThread',
            method: 'PATCH',
            body,
        }),
        invalidatesTags: ['Threads'],
        async onQueryStarted(
            { id, title, isFavorite },
            { dispatch, queryFulfilled },
        ) {
            const threadUpdateResult = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    id,
                    (draft) => {
                        if (title) {
                            draft.title = title;
                        }
                        if (isFavorite !== undefined) {
                            draft.isFavorite = isFavorite;
                        }
                    },
                ),
            );
            const threadListResult = dispatch(
                auroraApi.util.updateQueryData('getThreads', 0, (draft) => {
                    const thread = draft.items.find((item) => item.id === id);
                    if (thread && isFavorite !== undefined) {
                        thread.isFavorite = isFavorite;
                    }
                    if (thread && title) {
                        thread.title = title;
                    }
                }),
            );
            try {
                await queryFulfilled;
            } catch {
                threadUpdateResult.undo();
                threadListResult.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadUpdate,
                        body: '',
                        position: 'top-start',
                    }),
                );
            }
        },
    });

import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxStoreProvider } from 'react-redux';

import './index.css';
import { App } from './App';
import { msalConfig } from './auth-config';
import { reportWebVitals } from './reportWebVitals';
import { reactPlugin } from './Services/AppInsights';
import { store } from './Services/StateManagement/store';

export const msalInstance = new PublicClientApplication(msalConfig);

//get initialize msalInstance
msalInstance.initialize();
const activeAccount = msalInstance.getActiveAccount();
if (!activeAccount) {
    // Account selection
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
}

//set the account
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const authenticationResult = event.payload as AuthenticationResult;
        const account = authenticationResult.account;
        msalInstance.setActiveAccount(account);
    }
});

//enable account storage event
msalInstance.enableAccountStorageEvents();

const rootElement = document.getElementById('root');
const appRoot = ReactDOM.createRoot(rootElement!); // Rename the variable 'root' to 'appRoot'

appRoot.render(
    <MsalProvider instance={msalInstance}>
        <AppInsightsContext.Provider value={reactPlugin}>
            <ReduxStoreProvider store={store}>
                <App instance={msalInstance} />
            </ReduxStoreProvider>
        </AppInsightsContext.Provider>
    </MsalProvider>,
);

reportWebVitals();
